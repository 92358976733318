import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ExhibitionSummary from "../components/exhibition-summary"


export default function Home({ location, data }) {
  return (
    <Layout location={location} title="Home">
      <h1>{data.site.siteMetadata.title}</h1>
      <div className="list-summary">
        {data.content.exhibitions.map((node, index) => (
          <ExhibitionSummary key={node.id} exhibition={node} images={node.file.images}/>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
query Exhibitions {
  content: allMarkdownRemark(filter: {fields: {slug: {glob: "/exhibitions/**/*"}}}, sort: {order: DESC, fields: frontmatter___date}) {
    exhibitions: nodes {
      id
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        gallery
      }
      file: parent {
        id
        ... on File {
          id
          name
          base
          images: children {
            id
            ... on File {
              id
              childImageSharp {
                fixed(width: 96, height: 96, fit: INSIDE) {
                  src
                  height
                  width
                  originalName
                  base64
                  srcSet
                }
                fields {
                  caption
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
