import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default function ExhibitionSummary(props) {
  const images = props.images.filter(i => i.childImageSharp !== undefined)
                      .sort((a, b) => {
                        return a.childImageSharp.fixed.originalName > b.childImageSharp.fixed.originalName
                      })
  return (
    <article>
      <Link to={props.exhibition.fields.slug} className="card-list-summary">
        <div className="card-list-summary--text">
          <header>{props.exhibition.frontmatter.title}</header>
          <p>Opened on {props.exhibition.frontmatter.date} <br />at {props.exhibition.frontmatter.gallery}</p>
        </div>
        <div className="card-list-summary--images">
          {images.map(({id, childImageSharp, __typename}) => {
            return (
              <Img key={id} fixed={childImageSharp.fixed} alt={childImageSharp.fields.caption} />
            )
          })}
        </div>
      </Link>
    </article>
  )
}
